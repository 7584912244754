"use client"

import { useEffect, useState } from "react"
import { SessionStorage } from "shared/lib/session-storage"

export const useSessionStorage = (key: string) => {
  const [value, setValue] = useState<string>()

  useEffect(() => {
    setValue(SessionStorage.getItem(key))

    const unsubscribe = SessionStorage.subscribe(key, setValue)

    return () => unsubscribe()
  }, [key, setValue])

  return value
}
