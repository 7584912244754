"use client"

import { useEffect, useState } from "react"
import { LocalStorage } from "shared/lib/local-storage"

export const useLocalStorage = (key: string) => {
  const [value, setValue] = useState<string>()

  useEffect(() => {
    setValue(LocalStorage.getItem(key))

    const unsubscribe = LocalStorage.subscribe(key, setValue)

    return () => unsubscribe()
  }, [key, setValue])

  return value
}
