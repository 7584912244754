"use client";

import { api, OrderStatus } from "shared/api";
import styles from "./order-review-popup.module.scss";
import { OrderReviewCard } from "features/order-review-card";
import { useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";
import { LocalStorage, SessionStorage, useLocalStorage, useSessionStorage } from "shared/lib";
import { IconX } from "@tabler/icons-react";
import { analytics } from "entity/analytics";
export interface IOrderReviewPopup {}
export const OrderReviewPopup = (props: IOrderReviewPopup) => {
  const [isHide, setIsHide] = useState(false);
  const [animate, setAnimate] = useState(false);
  const orders = api.order.useOrders([OrderStatus.COMPLETED]);
  const hiddenOrderIds = useLocalStorage("hidden_order_ids_order_review_popup");
  const hideOrderReviewPopupSession = useSessionStorage("hide_order_review_popup");
  useEffect(() => {
    if (hideOrderReviewPopupSession) {
      setAnimate(true);
      setTimeout(() => {
        setIsHide(true);
      }, 300);
    }
  }, [hideOrderReviewPopupSession]);
  const order = useMemo(() => {
    const hiddenOrderIdsData = JSON.parse(hiddenOrderIds || "[]");
    return orders.data?.filter(it => !hiddenOrderIdsData.includes(it.id)).find(it => !it.review);
  }, [hiddenOrderIds, orders]);
  useEffect(() => {
    if (!order || isHide) return;
    analytics.send("order_review_popup.view", {
      orderId: order.id
    });
  }, [order]);
  if (!orders.data?.length || isHide) return null;
  if (!order) return;
  const onHide = () => {
    setAnimate(true);
    setTimeout(() => {
      setIsHide(true);
      sessionStorage.setItem("hide_order_review_popup", "true");
    }, 300);
  };
  const onClickClose = () => {
    setAnimate(true);
    setTimeout(() => {
      setIsHide(true);
      const hiddenOrderIdsData = JSON.parse(hiddenOrderIds || "[]");
      LocalStorage.setItem("hidden_order_ids_order_review_popup", JSON.stringify(hiddenOrderIdsData.concat(order.id)));
      SessionStorage.setItem("hide_order_review_popup", "true");
    }, 300);
    analytics.send("order_review_popup.close", {
      orderId: order.id
    });
  };
  const animation = {
    initial: {
      transform: "translateY(120%)"
    },
    animate: {
      transform: "translateY(0)",
      transition: {
        delay: 1.5,
        duration: 0.3
      }
    },
    exit: {
      transform: "translateY(120%)",
      transition: {
        duration: 0.3
      }
    }
  };
  return <motion.div initial={animation.initial} animate={animate ? animation.exit : animation.animate} className={styles.order_review_popup} data-sentry-element="unknown" data-sentry-component="OrderReviewPopup" data-sentry-source-file="order-review-popup.tsx">
      <div className={styles.header}>
        <IconX stroke={2} onClick={onClickClose} className={styles.close} data-sentry-element="IconX" data-sentry-source-file="order-review-popup.tsx" />
      </div>
      <div className={styles.body}>
        <OrderReviewCard order={order} onClose={onHide} mutate={orders.mutate} data-sentry-element="OrderReviewCard" data-sentry-source-file="order-review-popup.tsx" />
      </div>
    </motion.div>;
};